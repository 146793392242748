import { useContext } from "react"
import { Mycontext } from "./contextApi/store2"

function ContextTestingComp(){
    // const { moses } = useContext(Mycontext)
    // console.log("value", moses);
    return(
        <h1>Testing</h1>
    )
}
export default ContextTestingComp